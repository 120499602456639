<template>
  <div class="page-container result">
    <div class="mx-auto pt-50px text-center">
      <template v-if="payResultStr === 'SUCCESS_URL'">
        <img class="mx-auto w-140px" src="../assets/pay_succeed.png" alt="succeed" />
        <div class="mt-20px text-primary text-huge">Payment finished</div>
      </template>
      <template v-if="payResultStr === 'FAIL_URL'">
        <img class="mx-auto w-140px" src="../assets/pay_failed.png" alt="succeed" />
        <div class="mt-20px text-failed text-huge">Payment failed</div>
      </template>
      <div v-if="result.order_sn" class="mt-20px text-center">
        Order Number: {{ result.order_sn }}
      </div>
      <div class="mt-30px text-center">
        <span class="pr-6px text-medium">{{ result.symbol }}</span>
        <span class="text-huge">{{ result.amount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { getParams } from '@/utils/assist';
  import $http from '@/utils/axios';
  export default {
    name: 'Result',
    data() {
      return {
        result: {
          id: '',
          payment: '',
          type: '',
          amount: '',
          symbol: '',
          order_sn: '',
        },
        payResultStr: '',
      };
    },
    async mounted() {
      const result = getParams('id,payment,type,amount,symbol,order_sn');
      this.payResultStr = result.type;
      this.result = Object.assign(this.result, result);
      if (result.payment && result.payment === 'paypal') {
        await this.getPaypalResultData(result.id);
      }
    },
    methods: {
      async getPaypalResultData(id) {
        try {
          const res = await $http.get('/sdk_paypal/capture', { params: { order_token: id } });
          if (res) {
            this.payResultStr = 'SUCCESS_URL';
          } else {
            this.payResultStr = 'FAIL_URL';
          }
        } catch (error) {
          this.payResultStr = 'FAIL_URL';
          return error;
        }
      },
    },
  };
</script>

<style scoped>

  .text-failed {
    color: #ff5267;
  }

</style>
